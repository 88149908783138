.roadbook {
    max-width: 90rem;
    margin: auto;
}

.roadbook h2 {
    text-align: center;
    color: #CC0000;
}

.roadbook .slideshow {
    text-align: center;
}

.roadbook .slideshow .image-gallery-right-nav {
    right: -50px;
}

.roadbook .slideshow .image-gallery-left-nav {
    left: -50px;
}

.roadbook .slideshow .image-gallery-right-nav:before,
.roadbook .slideshow .image-gallery-left-nav:before {
    color: #CC0000;
}

.roadbook .section {
    padding: 5rem 0 0 0;
}

.roadbook .section div {
    padding: 1rem 0 0 0;
}

.roadbook .section ul {
    text-align: left;
}

.roadbook .question {
    color: #CC0000;
}

.roadbook .answer {
    font-style: italic;
}

.roadbook img {
    margin: 3rem auto 3rem auto;
    width: 80%;
    max-height: none;
}

.roadbook img.medium {
    max-width: 35rem;
    max-height: none;
}

.roadbook img.small {
    max-width: 15rem;
    max-height: none;
}

.roadbook .slideshow img {
    margin: 0;
}