.content article {
    width: 90rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 2rem 0 4rem 0;
}

.content article .return-link {
    font-size: 0.6em;
    color: #CC0000;
}

.content article .section-text em {
    color: #CC0000;
}

.content article .section-text .color-green {
    color: #93c47d;
}

.content article .section-image img {
    text-align: center;
    max-width: 100%;
    max-height: 450px;
}

.content article h2 {
    font-weight: bold;
}

@media screen and (max-width: 62rem) {
    .content article {
        height: auto;
        width: 100%;
    }

    .content article .section-image img {
        width: 100%;
        height: auto;
        max-height: none;
    }

}