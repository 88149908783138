body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: inherit;
  line-height: inherit;
}

/* Remove bootstrap surcharge */
html{
  font-size: inherit;
}