.footer {
    width: 85%;
    height: 15rem;
    background-color: #CC0000;
    margin-left: 15%;
}

.footer .content {
    max-width: 90rem;
    margin: auto;
    text-align: center;
}

.footer .content .text {
    padding: 2rem 0 2rem 0;
    font-size: 1.4em;
    line-height: 2;
}

.footer a {
    text-decoration: none;
    padding: 0 3rem;
}

.footer a img {
    height: 4rem;
    width: 4rem;
}

@media screen and (max-width: 62rem) {
    .footer {
        height: auto;
        width: 100%;
        margin-left: 0;
    }
}