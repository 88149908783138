.content {
    width: auto;
    margin-left: 15%;
}

.content h2 {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}


.content nav {
    text-align: center;
}

.content nav .pagination .active a {
    background-color: #CC0000;
    border-color: #CC0000;
    color: #fff;
}

.content nav .pagination .disabled a {
    color: #777;
}

.content nav .pagination li a {
    color: #CC0000;
}

.event {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
    text-align: left;
}

.event .article-teaser {
    text-align: left;
    width: 75rem;
    height: 15rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.2em;
    background-color: #ebebeb;
}

.event .article-content {
    width: 50%;
    height: 15rem;
    display: inline-block;
    padding-left: 5rem;
    bottom: 0;
}

.event .link-more {
    width: 10%;
    height: 15rem;
    text-align: center;
    padding-top: 2rem;
    float: right;
}

.event .link-more .event-date {
    padding-bottom: 9rem;
}

.event .link-more a {
    color: #CC0000;
}

.event .title {
    color: #CC0000;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.event img {
    display: inline-block;
    max-height: 15rem;
    max-width: 40%;
    vertical-align: top;
}

@media screen and (max-width: 1420px) {
    .event .article-teaser{
        width: 100%;
    }
}

@media screen and (max-width: 1050px) {
    .event {
        width: 100%;
        margin: auto;
    }

    .event .article-teaser {
        height: auto;
        width: 100%;
    }

    .event .link-more {
        width: 100%;
        height: auto;
        text-align: left;
        float: none;
    }

    .event .link-more .event-date {
        padding-bottom: 2rem;
        padding-left: 5rem;
        display: inline-block;
    }

    .event .link-more a {
        float: right;
    }
}

@media screen and (max-width: 62rem) {
    .content {
        width: auto;
        margin-left: 0;
    }

    .event .article-teaser {
        width: auto;
    }

    .event .link-more{
        text-align: center;
    }

    .event .link-more .event-date {
        display: block;
        padding-left: 0;
    }

    .event .link-more a {
        float: none;
    }

    .event img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    .event .article-content {
        width: 100%;
    }
}