.subscribe {
    width: 90rem;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 5rem 0 4rem 0;
}

.alert {
    font-size: 2.5em;
    color: #cc0000;
}

.checkered-main {
    margin-top: 20rem;
}

.subscribe .description {
    padding: 0 5rem 5rem 5rem;
    font-size: xx-large;
}

.subscribe a {
    text-decoration: none;
}

.subscribe img {
    width: 40%;
}

@media screen and (max-width: 62rem) {
    .subscribe {
        height: auto;
        width: 100%;
    }

    .subscribe img {
        width: auto;
        max-width: 100%;
    }

    .checkered-main {
        margin-top: 0;
    }
}