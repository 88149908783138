.header {
    width:15%;
    height: 100%;
}

.content {
    width: auto;
    margin-left: 15%;
}

.checkered-main {
    height: 90px;
    width: 100%;
    background: url("asset/bg-checkered.png");
    background-size: contain;
    background-repeat: repeat-x;
    margin-top: 320px;
}

.checkered {
    height: 50px;
    width: 100%;
    background: url("asset/bg-checkered.png");
    background-size: contain;
    background-repeat: repeat-x;
}

.affiche, .text {
    width: 90rem;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 5rem 0 4rem 0;
}

.text h3 {
    margin-bottom: 2rem;
    font-weight: bold;
}
.text h4 {
    font-size: larger;
    margin-bottom: 1rem;
    margin-top: 5rem;
    text-decoration: underline;
    text-align: left;
}

.text .color-text {
    color: #CC0000;
}

.text img{
    max-width: 100%;
    max-height: 30rem;
}

.text a.red-link {
    color: red;
}

.text img.img-sapin {
    max-width: 50%;
}

.text .green {
    color: #CC0000;
    font-style: italic;
}

.affiche img {
    width: 100%;
}

@media screen and (max-width: 62rem) {
    .affiche, .text {
        height: auto;
        width: 100%;
    }

    .header {
        height: auto;
        width: 100%;
    }

    .content {
        width: auto;
        margin: auto;
    }

    .checkered-main {
        margin-top: 0;
    }
}