body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: inherit;
  line-height: inherit;
}

/* Remove bootstrap surcharge */
html{
  font-size: inherit;
}
.header {
    width:15%;
    height: 100%;
}

.content {
    width: auto;
    margin-left: 15%;
}

.checkered-main {
    height: 90px;
    width: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gQTFwYlJEOIrAAAAX1JREFUeNrt1MENACAQAkGx/56xhfNnzEwJZEPaLsaSGGFimwBhISyEBcJCWAgLhIWwEBYIC2EhLBAWwkJYICyEhbBAWAgLYYGwEBbCAmEhLIQFwkJYCAuEhbAQFggLYSEsEBbCQlggLISFsEBYCAthgbAQFsICYSEshAXCQlgIC4SFsBAWCAthISwQFsJCWCAshIWwQFgIC2GBsBAWwgJhISyEBcJCWAgLhIWwEBYIC2EhLBAWwkJYCAuEhbAQFggLYSEsEBbCQlggLN4WE1xpawSPhbAQFggLYSEsEBbCQlggLISFsEBYCAthgbAQFsICYSEshAXCQlgIC4SFsBAWCAthISwQFsJCWCAshIWwQFgIC2EhLBAWwkJYICyEhbBAWAgLYYGwEBbCAmEhLIQFwkJYCAuEhbAQFggLYSEsEBbCQlggLISFsEBYCAthgbAQFsICYSEshAXCQlgIC4SFsBAWCAthISwQFsJCWCAshIWwQFgIC2GBsBAWHzjYtAeM40OvagAAAABJRU5ErkJggg==);
    background-size: contain;
    background-repeat: repeat-x;
    margin-top: 320px;
}

.checkered {
    height: 50px;
    width: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gQTFwYlJEOIrAAAAX1JREFUeNrt1MENACAQAkGx/56xhfNnzEwJZEPaLsaSGGFimwBhISyEBcJCWAgLhIWwEBYIC2EhLBAWwkJYICyEhbBAWAgLYYGwEBbCAmEhLIQFwkJYCAuEhbAQFggLYSEsEBbCQlggLISFsEBYCAthgbAQFsICYSEshAXCQlgIC4SFsBAWCAthISwQFsJCWCAshIWwQFgIC2GBsBAWwgJhISyEBcJCWAgLhIWwEBYIC2EhLBAWwkJYCAuEhbAQFggLYSEsEBbCQlggLN4WE1xpawSPhbAQFggLYSEsEBbCQlggLISFsEBYCAthgbAQFsICYSEshAXCQlgIC4SFsBAWCAthISwQFsJCWCAshIWwQFgIC2EhLBAWwkJYICyEhbBAWAgLYYGwEBbCAmEhLIQFwkJYCAuEhbAQFggLYSEsEBbCQlggLISFsEBYCAthgbAQFsICYSEshAXCQlgIC4SFsBAWCAthISwQFsJCWCAshIWwQFgIC2GBsBAWHzjYtAeM40OvagAAAABJRU5ErkJggg==);
    background-size: contain;
    background-repeat: repeat-x;
}

.affiche, .text {
    width: 90rem;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 5rem 0 4rem 0;
}

.text h3 {
    margin-bottom: 2rem;
    font-weight: bold;
}
.text h4 {
    font-size: larger;
    margin-bottom: 1rem;
    margin-top: 5rem;
    text-decoration: underline;
    text-align: left;
}

.text .color-text {
    color: #CC0000;
}

.text img{
    max-width: 100%;
    max-height: 30rem;
}

.text a.red-link {
    color: red;
}

.text img.img-sapin {
    max-width: 50%;
}

.text .green {
    color: #CC0000;
    font-style: italic;
}

.affiche img {
    width: 100%;
}

@media screen and (max-width: 62rem) {
    .affiche, .text {
        height: auto;
        width: 100%;
    }

    .header {
        height: auto;
        width: 100%;
    }

    .content {
        width: auto;
        margin: auto;
    }

    .checkered-main {
        margin-top: 0;
    }
}
*,
*:after,
*::before {
    box-sizing: border-box;
}

.nare-menu-header-wrapper {
    z-index: 100;
    position: fixed;
    min-height: 100%;
    width: 15%;
    border-left: 20px solid #CC0000;
    border-right: 5px solid #CC0000;
}

.nare-menu-header {
    margin: auto;
}

.nare-menu-header .nare-logo {
    height: 80px;
    margin: auto;
    max-width: 100%;
}

.nare-menu-header .nare-logo:hover {
    cursor: pointer;
}

.nare-menu-header .nare-nav {
    margin: auto;
    padding: 2rem 0 0 0;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    font-family: 'DIN Next W02 Bold',Arial,sans-serif;
    text-align: center;
}

.nare-menu-header .nare-nav a:first-child {

}

.nare-menu-header .nare-nav a {
    width: 100%;
    position: relative;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.35em;

    text-decoration: none;
    display: inline-block;
    margin: 0 25px 25px auto;
    color: #0f0f0f;
    font-weight: bold;

    padding: 10px;
    text-shadow: none;
    transition: color 0.3s;
}

.nare-menu-header .nare-nav a .light {
    font-size: 0.7rem;
    color: #4f4f4f;
}

.nare-menu-header .nare-nav a::before,
.nare-menu-header .nare-nav a::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    content: '';
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(-10px);
}

.nare-menu-header .nare-nav a::before {
    top: 0;
    transform: translateY(-10px);
}

.nare-menu-header .nare-nav a::after {
    bottom: 0;
    transform: translateY(10px);
}

.nare-menu-header .nare-nav a:hover,
.nare-menu-header .nare-nav a:focus {
    color: #CC0000;
}

.nare-menu-header .nare-nav a:hover .rose,
.nare-menu-header .nare-nav a:focus .rose{
    color: #f26b98;
}

.nare-menu-header .nare-nav a:hover .green,
.nare-menu-header .nare-nav a:focus .green{
    color: #93c47d;
}

.nare-menu-header .nare-nav a:hover .blue,
.nare-menu-header .nare-nav a:focus .blue{
    color: #0055A4;
}

.nare-menu-header .nare-nav a:hover .bordeau,
.nare-menu-header .nare-nav a:focus .bordeau{
    color: #53000a;
}

.nare-menu-header .nare-nav a:hover .brown,
.nare-menu-header .nare-nav a:focus .brown{
    color: #755a4d;
}

.nare-menu-header .nare-nav a:hover .yellow,
.nare-menu-header .nare-nav a:focus .yellow{
    color: #dca502;
}

.nare-menu-header .nare-nav a:hover .orange,
.nare-menu-header .nare-nav a:focus .orange{
    color: #ff8646;
}

.nare-menu-header .nare-nav a:hover .beige,
.nare-menu-header .nare-nav a:focus .beige{
    color: #f4eee2;
}

.nare-menu-header .nare-nav a:hover .light,
.nare-menu-header .nare-nav a:focus {
    font-size: 1.35rem;
}

.nare-menu-header .nare-nav a.soon {
    pointer-events: none;
}

.nare-menu-header .nare-nav a:hover::before,
.nare-menu-header .nare-nav a:focus::before,
.nare-menu-header .nare-nav a:hover::after,
.nare-menu-header .nare-nav a:focus::after {
    opacity: 1;
    transform: translateY(0px);
}

@media screen and (max-height: 85rem) {
    .content {
        border-left: 5px solid #CC0000;
    }

    .nare {
        border-left: 20px solid #CC0000;
    }

    .nare-menu-header-wrapper {
        position: absolute;
        min-height: 100%;
        width: 15%;
        border-left: none;
        border-right: none;
    }
}

@media screen and (max-width: 62rem) {
    .nare-menu-header-wrapper {
        z-index: 100;
        position: relative;
        height: auto;
        width: 100%;
        background-color: #ffffff;
    }

    .nare-menu-header .nare-nav {
        width: 100%;
    }

    .nare-menu-header .nare-nav a:hover,
    .nare-menu-header .nare-nav a:focus {
        color: #000;
    }

    .nare-menu-header .nare-nav a {
        margin: 0 0 25px auto;
        padding: 0;
    }

    .content {
        border-left: none;
    }

    .nare {
        border-left: none;
    }
}

.nare-title {
    height: 34.4rem;
}

.nare-title .embla {
    max-height: 54.4rem;
}

.nare-title .logo {
    height: 20rem;
    margin: 0 0 0 0;
    vertical-align: inherit;
    max-width: 100%;
}

.nare-title .title-description{
    height: 270px;
    background: url(/static/media/texture-asphalte.5dbab31c.jpg);
}

.nare-title .title-description .description{
    padding-top: 160px;
}

.nare-title .title-description img.r5{
    float: right;
    margin-right: 20px;
}

.nare-title .title-description img.porshe{
    float: left;
    margin-left: 20px;
}

.nare-title .background {
    height: 6.6rem;
}

.embla {
    overflow: hidden;
}
.embla__container {
    display: flex;
}
.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}

.embla__slide img {
    width: 100%;
}


@media screen and (max-width: 62rem) {
    .nare-title{
        height: auto;
        font-size: 1.2em;
    }

    .nare-title .logo {
        height: auto;
    }

    .nare-title .title {
        width: 100%;
    }

    .nare-title .title-description {
        height: auto;
    }

    .nare-title .title-description img{
        display: none;
    }
}
.footer {
    width: 85%;
    height: 15rem;
    background-color: #CC0000;
    margin-left: 15%;
}

.footer .content {
    max-width: 90rem;
    margin: auto;
    text-align: center;
}

.footer .content .text {
    padding: 2rem 0 2rem 0;
    font-size: 1.4em;
    line-height: 2;
}

.footer a {
    text-decoration: none;
    padding: 0 3rem;
}

.footer a img {
    height: 4rem;
    width: 4rem;
}

@media screen and (max-width: 62rem) {
    .footer {
        height: auto;
        width: 100%;
        margin-left: 0;
    }
}
.popup-img {
    margin-top: 1.5em;
}


.text-popup {
    width: 100%;
    text-align: center;
    margin: auto;
}

.popup-img img {
    max-width: 100%;
}

.popup-text a {
    color: red;
}
.article-block {
    max-width: 75rem;
    margin: auto;
}

.article-block h2 {
    font-size: 2.5rem;
}

.article-block h2 .blue {
    color: #0055A4;
}

.article-block h2 .red {
    color: #CC0000;
}

.article-block article h2 {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}


.article-block nav {
    text-align: center;
}

.article-block nav .pagination .active a {
    background-color: #CC0000;
    border-color: #CC0000;
    color: #fff;
}

.article-block nav .pagination .disabled a {
    color: #777;
}

.article-block nav .pagination li a {
    color: #CC0000;
}

.article {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
    text-align: left;
}

.article .article-teaser {
    text-align: left;
    width: 75rem;
    height: 15rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.2em;
    background-color: #ebebeb;
}

.article .article-teaser.article-event {
    border-top: #CC0000 solid;
}

.article .article-teaser.article-news {
    border-top: #0055A4 solid;
}

.article .article-content {
    width: 50%;
    height: 15rem;
    display: inline-block;
    padding-left: 5rem;
    bottom: 0;
}

.article .link-more {
    width: 10%;
    height: 15rem;
    text-align: center;
    padding-top: 2rem;
    float: right;
}

.article .link-more .article-date {
    padding-bottom: 9rem;
}

.article .link-more a {
    color: #CC0000;
}

.article .article-news .link-more a {
    color: #0055A4;
}

.article .title {
    color: #CC0000;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.article img {
    display: inline-block;
    max-height: 15rem;
    max-width: 40%;
    vertical-align: top;
}

@media screen and (max-width: 1420px) {
    .article .article-teaser{
        width: 100%;
    }
}

@media screen and (max-width: 1050px) {
    .article {
        width: 100%;
        margin: auto;
    }

    .article .article-teaser {
        height: auto;
        width: 100%;
    }

    .article .link-more {
        width: 100%;
        height: auto;
        text-align: left;
        float: none;
    }

    .article .link-more .article-date {
        padding-bottom: 2rem;
        padding-left: 5rem;
        display: inline-block;
    }

    .article .link-more a {
        float: right;
    }
}

@media screen and (max-width: 62rem) {
    .article .article-teaser {
        width: auto;
    }

    .article .link-more{
        text-align: center;
    }

    .article .link-more .article-date {
        display: block;
        padding-left: 0;
    }

    .article .link-more a {
        float: none;
    }

    .article img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    .article .article-content {
        width: 100%;
    }
}
.palmares-tab {
    height: auto;
    width: 100%;
    background-color: #cc0000;
}

.result-cup {
    height: 8rem;
    margin-right: 10rem;
    margin-left: 5rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5rem;
}

.tab-link a {
    text-decoration: none;
    color: black;
}

.tab-link {
    display: inline-block;
    width: 13rem;
    height: 10rem;
    text-align: center;
    vertical-align: middle;
}

.tab-link.selected {
    background-color: #fff;
}

.tab-link:hover {
    cursor: pointer;
    background-color: #fff;
}

.tab-link img {
    width: 10rem;
    padding: 0 2rem 0 2rem;
    margin: 2rem 0 0 0;
}

.result {
    width: 90rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 5rem 0 0 0;
    text-align: center;
}

.result img {
    max-width: 85rem;
}

.title-gogole {
    margin-top: -5rem;
    margin-bottom: 5rem;
    font-size: 1.5em;
    text-align: center;
}

.title {
    color: #cc0000;
    font-size: 1.7em;
    text-align: center;
}

.table {
    margin-top: 2em;
}

.table .title {
    color: black;
    font-size: 1.5em;
    text-align: center;
}

.text {
    color: black;
    text-align: center;
}

@media screen and (max-width: 62rem) {
    .result {
        height: auto;
        width: 100%;
    }
}
.content {
    width: auto;
    margin-left: 15%;
}

.content h2 {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}


.content nav {
    text-align: center;
}

.content nav .pagination .active a {
    background-color: #CC0000;
    border-color: #CC0000;
    color: #fff;
}

.content nav .pagination .disabled a {
    color: #777;
}

.content nav .pagination li a {
    color: #CC0000;
}

.event {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
    text-align: left;
}

.event .article-teaser {
    text-align: left;
    width: 75rem;
    height: 15rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.2em;
    background-color: #ebebeb;
}

.event .article-content {
    width: 50%;
    height: 15rem;
    display: inline-block;
    padding-left: 5rem;
    bottom: 0;
}

.event .link-more {
    width: 10%;
    height: 15rem;
    text-align: center;
    padding-top: 2rem;
    float: right;
}

.event .link-more .event-date {
    padding-bottom: 9rem;
}

.event .link-more a {
    color: #CC0000;
}

.event .title {
    color: #CC0000;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.event img {
    display: inline-block;
    max-height: 15rem;
    max-width: 40%;
    vertical-align: top;
}

@media screen and (max-width: 1420px) {
    .event .article-teaser{
        width: 100%;
    }
}

@media screen and (max-width: 1050px) {
    .event {
        width: 100%;
        margin: auto;
    }

    .event .article-teaser {
        height: auto;
        width: 100%;
    }

    .event .link-more {
        width: 100%;
        height: auto;
        text-align: left;
        float: none;
    }

    .event .link-more .event-date {
        padding-bottom: 2rem;
        padding-left: 5rem;
        display: inline-block;
    }

    .event .link-more a {
        float: right;
    }
}

@media screen and (max-width: 62rem) {
    .content {
        width: auto;
        margin-left: 0;
    }

    .event .article-teaser {
        width: auto;
    }

    .event .link-more{
        text-align: center;
    }

    .event .link-more .event-date {
        display: block;
        padding-left: 0;
    }

    .event .link-more a {
        float: none;
    }

    .event img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    .event .article-content {
        width: 100%;
    }
}
.content article {
    width: 90rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 2rem 0 4rem 0;
}

.content article .return-link {
    font-size: 0.6em;
    color: #CC0000;
}

.content article .section-text em {
    color: #CC0000;
}

.content article .section-text .color-green {
    color: #93c47d;
}

.content article .section-image img {
    text-align: center;
    max-width: 100%;
    max-height: 450px;
}

.content article h2 {
    font-weight: bold;
}

@media screen and (max-width: 62rem) {
    .content article {
        height: auto;
        width: 100%;
    }

    .content article .section-image img {
        width: 100%;
        height: auto;
        max-height: none;
    }

}
.roadbook {
    max-width: 90rem;
    margin: auto;
}

.roadbook h2 {
    text-align: center;
    color: #CC0000;
}

.roadbook .slideshow {
    text-align: center;
}

.roadbook .slideshow .image-gallery-right-nav {
    right: -50px;
}

.roadbook .slideshow .image-gallery-left-nav {
    left: -50px;
}

.roadbook .slideshow .image-gallery-right-nav:before,
.roadbook .slideshow .image-gallery-left-nav:before {
    color: #CC0000;
}

.roadbook .section {
    padding: 5rem 0 0 0;
}

.roadbook .section div {
    padding: 1rem 0 0 0;
}

.roadbook .section ul {
    text-align: left;
}

.roadbook .question {
    color: #CC0000;
}

.roadbook .answer {
    font-style: italic;
}

.roadbook img {
    margin: 3rem auto 3rem auto;
    width: 80%;
    max-height: none;
}

.roadbook img.medium {
    max-width: 35rem;
    max-height: none;
}

.roadbook img.small {
    max-width: 15rem;
    max-height: none;
}

.roadbook .slideshow img {
    margin: 0;
}
.passed-events {
    text-align: center;
}

.passed-events img {
    width: 40%;
}

@media screen and (max-width: 62rem) {
    .passed-events img {
        width: auto;
        max-width: 100%;
    }
}
.subscribe {
    width: 90rem;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 5rem 0 4rem 0;
}

.alert {
    font-size: 2.5em;
    color: #cc0000;
}

.checkered-main {
    margin-top: 20rem;
}

.subscribe .description {
    padding: 0 5rem 5rem 5rem;
    font-size: xx-large;
}

.subscribe a {
    text-decoration: none;
}

.subscribe img {
    width: 40%;
}

@media screen and (max-width: 62rem) {
    .subscribe {
        height: auto;
        width: 100%;
    }

    .subscribe img {
        width: auto;
        max-width: 100%;
    }

    .checkered-main {
        margin-top: 0;
    }
}
.content {
    width: auto;
}

.content h2.sponsor-title {
    text-align: center;
    margin-top: 4rem;
    font-size: 2.5rem;
}

.content h3.sponsor-title {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2rem;
}

.sponsor {
    text-align: center;
}

.sponsor .logo-link {
    padding: 3rem;
}

.sponsor .logo-link img {
    max-width: 25%;
    padding-bottom: 5rem;
}

@media screen and (max-width: 62rem) {
    .sponsor .logo-link img {
        max-width: 100%;
    }
}
.gallery{
    min-height: 95%;
}

.react-images__blanket {
    z-index: 101;
}

.react-images__main {
    z-index: 105;
}

.react-images__container {
    z-index: 105;
}

.react-images__view {
    z-index: 110;
}

.react-images__view-image--isModal {
    z-index: 115;
}

.gallery-tab {
  height: auto;
  width: 100%;
  background-color: #cc0000;
}

.tab-item {
  display: inline-block;
  width: 13rem;
  height: 10rem;
  vertical-align: middle;
  text-align: center;
}

.tab-item div {
  text-decoration: none;
  color: black;
}

.tab-item img {
  width: 10rem;
  padding: 0 2rem 0 2rem;
  margin: 2rem 0 0 0;
}

.tab-item:hover {
  cursor: pointer;
  background-color: #fff;
}
