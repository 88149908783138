*,
*:after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.nare-menu-header-wrapper {
    z-index: 100;
    position: fixed;
    min-height: 100%;
    width: 15%;
    border-left: 20px solid #CC0000;
    border-right: 5px solid #CC0000;
}

.nare-menu-header {
    margin: auto;
}

.nare-menu-header .nare-logo {
    height: 80px;
    margin: auto;
    max-width: 100%;
}

.nare-menu-header .nare-logo:hover {
    cursor: pointer;
}

.nare-menu-header .nare-nav {
    margin: auto;
    padding: 2rem 0 0 0;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    font-family: 'DIN Next W02 Bold',Arial,sans-serif;
    text-align: center;
}

.nare-menu-header .nare-nav a:first-child {

}

.nare-menu-header .nare-nav a {
    width: 100%;
    position: relative;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.35em;

    text-decoration: none;
    display: inline-block;
    margin: 0 25px 25px auto;
    color: #0f0f0f;
    font-weight: bold;

    padding: 10px;
    text-shadow: none;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

.nare-menu-header .nare-nav a .light {
    font-size: 0.7rem;
    color: #4f4f4f;
}

.nare-menu-header .nare-nav a::before,
.nare-menu-header .nare-nav a::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}

.nare-menu-header .nare-nav a::before {
    top: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}

.nare-menu-header .nare-nav a::after {
    bottom: 0;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
}

.nare-menu-header .nare-nav a:hover,
.nare-menu-header .nare-nav a:focus {
    color: #CC0000;
}

.nare-menu-header .nare-nav a:hover .rose,
.nare-menu-header .nare-nav a:focus .rose{
    color: #f26b98;
}

.nare-menu-header .nare-nav a:hover .green,
.nare-menu-header .nare-nav a:focus .green{
    color: #93c47d;
}

.nare-menu-header .nare-nav a:hover .blue,
.nare-menu-header .nare-nav a:focus .blue{
    color: #0055A4;
}

.nare-menu-header .nare-nav a:hover .bordeau,
.nare-menu-header .nare-nav a:focus .bordeau{
    color: #53000a;
}

.nare-menu-header .nare-nav a:hover .brown,
.nare-menu-header .nare-nav a:focus .brown{
    color: #755a4d;
}

.nare-menu-header .nare-nav a:hover .yellow,
.nare-menu-header .nare-nav a:focus .yellow{
    color: #dca502;
}

.nare-menu-header .nare-nav a:hover .orange,
.nare-menu-header .nare-nav a:focus .orange{
    color: #ff8646;
}

.nare-menu-header .nare-nav a:hover .beige,
.nare-menu-header .nare-nav a:focus .beige{
    color: #f4eee2;
}

.nare-menu-header .nare-nav a:hover .light,
.nare-menu-header .nare-nav a:focus {
    font-size: 1.35rem;
}

.nare-menu-header .nare-nav a.soon {
    pointer-events: none;
}

.nare-menu-header .nare-nav a:hover::before,
.nare-menu-header .nare-nav a:focus::before,
.nare-menu-header .nare-nav a:hover::after,
.nare-menu-header .nare-nav a:focus::after {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}

@media screen and (max-height: 85rem) {
    .content {
        border-left: 5px solid #CC0000;
    }

    .nare {
        border-left: 20px solid #CC0000;
    }

    .nare-menu-header-wrapper {
        position: absolute;
        min-height: 100%;
        width: 15%;
        border-left: none;
        border-right: none;
    }
}

@media screen and (max-width: 62rem) {
    .nare-menu-header-wrapper {
        z-index: 100;
        position: relative;
        height: auto;
        width: 100%;
        background-color: #ffffff;
    }

    .nare-menu-header .nare-nav {
        width: 100%;
    }

    .nare-menu-header .nare-nav a:hover,
    .nare-menu-header .nare-nav a:focus {
        color: #000;
    }

    .nare-menu-header .nare-nav a {
        margin: 0 0 25px auto;
        padding: 0;
    }

    .content {
        border-left: none;
    }

    .nare {
        border-left: none;
    }
}
