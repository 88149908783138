.nare-title {
    height: 34.4rem;
}

.nare-title .embla {
    max-height: 54.4rem;
}

.nare-title .logo {
    height: 20rem;
    margin: 0 0 0 0;
    vertical-align: inherit;
    max-width: 100%;
}

.nare-title .title-description{
    height: 270px;
    background: url("../asset/texture-asphalte.jpg");
}

.nare-title .title-description .description{
    padding-top: 160px;
}

.nare-title .title-description img.r5{
    float: right;
    margin-right: 20px;
}

.nare-title .title-description img.porshe{
    float: left;
    margin-left: 20px;
}

.nare-title .background {
    height: 6.6rem;
}

.embla {
    overflow: hidden;
}
.embla__container {
    display: flex;
}
.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}

.embla__slide img {
    width: 100%;
}


@media screen and (max-width: 62rem) {
    .nare-title{
        height: auto;
        font-size: 1.2em;
    }

    .nare-title .logo {
        height: auto;
    }

    .nare-title .title {
        width: 100%;
    }

    .nare-title .title-description {
        height: auto;
    }

    .nare-title .title-description img{
        display: none;
    }
}