.gallery{
    min-height: 95%;
}

.react-images__blanket {
    z-index: 101;
}

.react-images__main {
    z-index: 105;
}

.react-images__container {
    z-index: 105;
}

.react-images__view {
    z-index: 110;
}

.react-images__view-image--isModal {
    z-index: 115;
}
