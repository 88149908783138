.passed-events {
    text-align: center;
}

.passed-events img {
    width: 40%;
}

@media screen and (max-width: 62rem) {
    .passed-events img {
        width: auto;
        max-width: 100%;
    }
}