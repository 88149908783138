.palmares-tab {
    height: auto;
    width: 100%;
    background-color: #cc0000;
}

.result-cup {
    height: 8rem;
    margin-right: 10rem;
    margin-left: 5rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5rem;
}

.tab-link a {
    text-decoration: none;
    color: black;
}

.tab-link {
    display: inline-block;
    width: 13rem;
    height: 10rem;
    text-align: center;
    vertical-align: middle;
}

.tab-link.selected {
    background-color: #fff;
}

.tab-link:hover {
    cursor: pointer;
    background-color: #fff;
}

.tab-link img {
    width: 10rem;
    padding: 0 2rem 0 2rem;
    margin: 2rem 0 0 0;
}

.result {
    width: 90rem;
    margin: auto;
    letter-spacing: 1px;
    font-size: 1.4em;
    line-height: 3rem;
    padding: 5rem 0 0 0;
    text-align: center;
}

.result img {
    max-width: 85rem;
}

.title-gogole {
    margin-top: -5rem;
    margin-bottom: 5rem;
    font-size: 1.5em;
    text-align: center;
}

.title {
    color: #cc0000;
    font-size: 1.7em;
    text-align: center;
}

.table {
    margin-top: 2em;
}

.table .title {
    color: black;
    font-size: 1.5em;
    text-align: center;
}

.text {
    color: black;
    text-align: center;
}

@media screen and (max-width: 62rem) {
    .result {
        height: auto;
        width: 100%;
    }
}