.gallery-tab {
  height: auto;
  width: 100%;
  background-color: #cc0000;
}

.tab-item {
  display: inline-block;
  width: 13rem;
  height: 10rem;
  vertical-align: middle;
  text-align: center;
}

.tab-item div {
  text-decoration: none;
  color: black;
}

.tab-item img {
  width: 10rem;
  padding: 0 2rem 0 2rem;
  margin: 2rem 0 0 0;
}

.tab-item:hover {
  cursor: pointer;
  background-color: #fff;
}