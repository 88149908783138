.content {
    width: auto;
}

.content h2.sponsor-title {
    text-align: center;
    margin-top: 4rem;
    font-size: 2.5rem;
}

.content h3.sponsor-title {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2rem;
}

.sponsor {
    text-align: center;
}

.sponsor .logo-link {
    padding: 3rem;
}

.sponsor .logo-link img {
    max-width: 25%;
    padding-bottom: 5rem;
}

@media screen and (max-width: 62rem) {
    .sponsor .logo-link img {
        max-width: 100%;
    }
}