.popup-img {
    margin-top: 1.5em;
}


.text-popup {
    width: 100%;
    text-align: center;
    margin: auto;
}

.popup-img img {
    max-width: 100%;
}

.popup-text a {
    color: red;
}